import React, {FC, Fragment, ReactNode} from 'react';
import {GlobalStyle} from '../styles/GlobalStyle';

type Props = {
	children: ReactNode;
}

const Main: FC<Props> = ({children}) =>
	<Fragment>
		<GlobalStyle />
		<main>{children}</main>
	</Fragment>;

export default Main;
