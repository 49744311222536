import React, {PureComponent, MouseEvent, ReactNode} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Table from 'react-bootstrap/Table';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import safeNavigate from '../utils/safeNavigate';
import {asUSD} from '../utils/formatters';
import {UserContext} from '../porter-package/';
import {TIERS, LINE_ITEMS} from '../constants/plans';
import Layout from '../layouts/Simple';

const Wrapper = styled.div`
	& {
		margin: 2em auto;
		width: 65%;
		font-weight: bold;
	}
`;

type Props = {

}

const MONTHLY = 'monthly';
const ANNUALLY = 'annually';

type State = {
	selectedPackage: PlanPackage;
	plan: 'monthly' | 'annually';
}

const getPackageFromId = (planId: string): PlanPackage | undefined =>
	Object
		.values(TIERS)
		.find(({id}) => id === planId);

const getPackageFromUrl = (): PlanPackage | undefined => {
	if (typeof document !== 'undefined') {
		const queryItem = document.location.search.replace('?', '');
		return getPackageFromId(queryItem);
	}

	return undefined;
};

const getButtonVariant = (currentPlan: PlanPackage) => (planId: PlanPackage['id']): string =>
	currentPlan.id === planId ?
		'primary' :
		'outline-secondary';

class Payment extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			selectedPackage: getPackageFromUrl() || TIERS.t1,
			plan: MONTHLY
		};

		this.handlePlanClick = this.handlePlanClick.bind(this);
		this.handleTierClick = this.handleTierClick.bind(this);
	}

	static contextType = UserContext

	handleTierClick(event: MouseEvent<HTMLButtonElement>): void {
		const target = event.target as HTMLButtonElement;
		this.setState({
			selectedPackage: getPackageFromId(target.id) || TIERS.t1
		});
	}

	handlePlanClick(event: MouseEvent<HTMLButtonElement>): void {
		const target = event.target as HTMLButtonElement;
		this.setState({
			plan: target.id as State['plan']
		});
	}

	render(): ReactNode {
		if (this.context.isLoggedIn) {
			safeNavigate('/dashboard/');
			return null;
		}

		const {selectedPackage, plan} = this.state;
		const variant = getButtonVariant(this.state.selectedPackage);
		return <Layout>
			<Helmet
				title="Sign Up for BeamFire"
			/>
			<header id="header"
				className="ex-header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h1>Start Using BeamFire Now!</h1>
						</div>
					</div>
				</div>
			</header>
			<div className="ex-basic-1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcrumbs">
								<a href="/">Home</a><i className="fa fa-angle-double-right" /><span>Sign Up</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="ex-basic-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="text-container">
								<h3>Confirm Plan</h3>
								<ButtonToolbar aria-label="Toolbar with Tiers and Plans">
									<ButtonGroup
										aria-label="Select Tier"
										className="mr-2">
										<Button
											id={TIERS.t1.id}
											onClick={this.handleTierClick}
											variant={variant(TIERS.t1.id)}>
											{TIERS.t1.title}
										</Button>
										<Button
											id={TIERS.t2.id}
											onClick={this.handleTierClick}
											variant={variant(TIERS.t2.id)}>
											{TIERS.t2.title}
										</Button>
										<Button
											id={TIERS.t3.id}
											onClick={this.handleTierClick}
											variant={variant(TIERS.t3.id)}>
											{TIERS.t3.title}
										</Button>
									</ButtonGroup>
									<ButtonGroup aria-label="Select Plan">
										<Button
											id={MONTHLY}
											onClick={this.handlePlanClick}
											variant={plan === MONTHLY ?
												'primary' :
												'outline-secondary'}>
											Monthly
										</Button>
										<Button
											id={ANNUALLY}
											onClick={this.handlePlanClick}
											variant={plan === ANNUALLY ?
												'primary' :
												'outline-secondary'}>
											Annual
										</Button>
									</ButtonGroup>
								</ButtonToolbar>
								<Table>
									<thead>
										<tr>
											<th style={{width: '30em'}}>Line Item</th>
											<th style={{width: '20em'}}>Amount</th>
											<th>Plan</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<strong>
													Order Total
												</strong>
												<br />
												<ul>
													{
														selectedPackage.baseItems
															.map((name, i) =>
																<li key={i}>
																	{name}
																</li>
															)
													}
													{
														LINE_ITEMS
															.filter(({minTier}) => minTier <= selectedPackage.tier)
															.map(({name, title}, i) =>
																<li
																	key={i}
																	title={title}>
																	{name}
																</li>
															)
													}
												</ul>
											</td>
											<td>
												{
													plan === MONTHLY ?
														asUSD(selectedPackage.monthly) :
														asUSD(selectedPackage.annual)
												}
											</td>
											<td>
												{plan.toLocaleUpperCase()}
												{
													plan === ANNUALLY ?
														<strong>
															{' '}{selectedPackage.annualDesc}
														</strong> :
														null
												}
											</td>
										</tr>
										<tr>
											<td>
												<strong>
													{
														selectedPackage.id === 't=1' ?
															'Due after trial' :
															'Due Today'
													}
												</strong>
											</td>
											<td>
												{
													plan === MONTHLY ?
														asUSD(selectedPackage.monthly) :
														asUSD(selectedPackage.annual)
												}
											</td>
										</tr>
										<tr>
											<td colSpan={3}>
												<Wrapper>
													Ready to Start? <a href="mailto:contact@beamfire.io">Contact Us</a>
												</Wrapper>
											</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>;
	}
}

export default Payment;
